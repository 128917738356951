@import "variables";
.dashboard-box {
  &__header {
    font-size: 25px;
    font-weight: bold;
  }
  &__wrapper {
    text-align: center;
    background: #ffffff;
    border-radius: 5px;
    color: $font-color;
    margin: 15px;
    padding: 15px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.24);
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.24);
    box-sizing: border-box;
    overflow: scroll;

    h1 {
      font-weight: 500;
      font-size: 1.5em;
      width: 100%;
    }

    &.mission-timebox-wrapper {
      grid-area: 1 / 1 / 2 / 2;
      max-height: 200px;
    }
    &.mission-vehicles-wrapper {
      max-height: 200px;
    }
    &.alarmed-units-wrapper {
      grid-area: 1 / 2 / 3 / 3;
      max-width: 800px;
      width: 80%;
    }

    &.forces-box-wrapper {
      grid-area: 1 / 3 / 4 / 4;
      padding: 25px;
    }
  }

  &__horizontal-entry {
    display: flex;
    justify-content: space-between;
    margin: 24px 0px;
    padding: 0px 5px;
    width: 100%;
    align-items: center;

    .forces-amount {
      font-size: 40px;
      font-weight: bold;
    }

    .forces-amount-total {
      font-size: 53px;
    }
  }

  &__centered {
    width: 100%;
    text-align: center;
    font-size: 53px;
    font-weight: bold;
  }

  &__spacer {
    width: 100%;
    border-bottom: 1px solid black;
    margin: 15px 0;

    &.no-border {
      border-bottom: none;
    }
  }

  &__mission-container {
    border: 1px solid black;
    border-radius: $border-radius;
    .dashboard-box__mission-container {
      background-color: $font-color;
      border-radius: $border-radius;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      p {
        color: white;
        font-size: 0.8em;
        margin: 0px;
        margin-left: 12px;
      }

      svg {
        height: 20px;
        width: 20px;
        color: white;
        margin-left: auto;
        margin-right: 12px;
      }
    }
  }

  &__overflowable-content {
    overflow: scroll;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__alarmed-units-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2px;
    transition: all 200ms;
    overflow: scroll;

    &.hidden {
      max-height: 0px;
    }

    &.visible {
      max-height: 200px;
      margin: 15px 0px;
      padding: 0px 5px;
    }
  }

  &__units-container {
    display: flex;
    flex-direction: row;

    p {
      font-size: 0.8em;
    }

    .dashboard-box__alarmed-name {
      margin-left: 1em;
    }
  }
}

.vehicle-editor {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;

  &__popup {
    .popup-component--close {
      display: none;
    }
  }

  hr {
    border-color: #097bed;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 10px;

    > div {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }

    &__crew {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }
}

.maplayerbar {
  &__wrapper {
    flex-shrink: 0;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    box-sizing: border-box;
    display: none;
    padding: 12px;
    &.active {
      display: flex;
    }
  }

  &__header-container {
    display: flex;
    gap: 12px;
    p {
      font-weight: bold;
    }
    svg {
      height: 20px;
      width: 20px;
      margin-left: auto;
    }
  }

  &__toggle-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
    p {
      padding: 10px 0;
    }
  }

  &__layers {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;

    .maplayer {
      &__wrapper {
        cursor: pointer;
        display: flex;
        gap: 12px;
        align-items: center;

        .toggle-component {
          margin-left: auto;
        }

        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}

#create-user {
  .create-user {
    &__account-type-container {
      display: flex;
      gap: 12px;
      padding: 8px;
    }
  }
  .create-user--qualifications-container {
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    p {
      font-weight: bold;
    }

    .single-qualification {
      .checkbox-component {
        margin-top: 12px;
      }
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 6px;
    }
  }
}
#edit-vehicle {
  .vehicle-preset-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px;
  }

  .vehicle-preset-deleteIcon {
    margin-top: 12px;
    height: 26px;
    width: 26px;
    cursor: pointer;
  }

  .vehicle-preset-image-container {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-left: 4px;
    img {
      max-width: 60%;
    }
  }
  .vehicle-edit {
    &__load-container {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }
}

#create-hydrant {
  .create-hydrant {
    &__coordinates-container {
      display: flex;
    }
  }
}

.contexify {
  position: absolute;
  background-color: #fff;
  padding: 20px 20px;
  border-radius: 5px;

  .contexify_separator {
    height: 1px;
    background-color: #f2f2f2;
  }

  .contexify_item {
    padding: 0 10px;
  }

  .context-menu__header {
    margin: 10px 0;
    padding: 0 10px;
    font-weight: bold;
    font-size: 1.1em;
  }
}

.time-control {
  padding: 5px;
  display: flex;
  flex-direction: column;

  &__title {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: space-between;

    > div {
      p:first-of-type {
        font-weight: 600;
      }
    }
  }

  &__slider {
    display: flex;
    gap: 5px;
    padding: 0 5px;

    &__time {
      display: flex;
    }

    &__input {
      display: flex;
      flex-direction: column;
      width: 100%;

      input[type="range"] {
        width: 100%;
        color: red;
      }

      &__info {
        position: relative;
        width: 90%;
        height: 10px;
        margin: 0 auto;

        > span {
          position: absolute;
          transform: translateX(-50%);
          font-size: 0.8rem;
          white-space: nowrap;
        }
      }
    }

    &__forward {
      display: flex;
      border-radius: 3px;
      padding: 5px;
      background-color: #0e1d3c;
      cursor: pointer;
    }
  }
}

.generate-mission {
  .generate-mission-header-container {
    display: flex;
    align-items: center;
    gap: 12px;
    svg {
      height: 20px;
      width: 20px;
    }
  }
  .header-component,
  .button-component {
    padding: 4px;
  }
  .generate-mission-button {
    height: 0;
    overflow: hidden;
    transition: all 0.2s;
  }
}
.address-form-component {
  &__coordinates {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
  }
}
