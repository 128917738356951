@import "variables";
.dashboard-page {
  &__content {
    display: grid;
    align-items: start;
    justify-items: center;
    grid-template-columns: min-content 1fr min-content;
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  &__wrapper {
    padding: 32px;

    h2 {
      padding: 12px;
    }
  }
}

.user-administration {
  &__button-container {
    display: flex;
    gap: 12px;

    .dropdown-component {
      width: 100%;
    }
    @keyframes pop-in {
      0% {
        opacity: 0;
        transform: scale(0.5);
      }
      100% {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}

.tactical-overview {
  &__header-container {
    display: flex;
    align-items: center;
    gap: 4%;
  }
  &__toggle-wrapper {
    gap: 4px;
    display: flex;
    align-items: center;

    p {
      font-weight: bold;
    }
  }

  &__crew,
  &__qualification {
    padding: 4px;
    display: flex;
    background-color: orange;
    color: white;
    font-weight: bold;

    &.yellow {
      background-color: $orange;
    }
    &.green {
      background-color: $green;
    }
    &.red {
      background-color: $red;
    }
  }

  &__crew-total,
  &__qualification-total {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__total-crew {
    margin-left: 4px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__padding {
    padding: 24px;
  }

  &__bottom-wrapper {
    margin-top: auto;

    .table-component {
      table {
        border-collapse: collapse;
      }
      h2,
      p {
        color: white;
        font-weight: bold;
      }
      td {
        color: white;
        font-weight: bold;
      }
      th {
        background-color: white;
        padding: 12px;
        font-weight: bold;
      }
      td {
        padding: 2em;
        background-color: #097bed;
      }
    }
  }
}
