//MapComponent
.map-wrapper {
  flex-grow: 1;
  z-index: 0;
  height: 100%;
  width: 100%;
  border: 1px solid transparent;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;

  &-marker {
    height: 50px;

    svg,
    img {
      transform: translate(-50%, -50%);
    }
  }
}

.leaflet-control-attribution {
  display: none;
}

//MapOverlay
.map-overlay-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  position: relative;

  .map-overlay {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: grid;
    grid-template-columns: auto 1fr 2fr auto auto 12px;
    grid-template-rows: 2fr 1fr 2fr 1fr 12px;
    grid-column-gap: 30px;
    gap: 10px;

    * {
      pointer-events: all;
    }

    &__time-control {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      margin: 0 120px 5px 120px;
    }
  }
}

@mixin overlay {
  pointer-events: all;
  background-color: white;
  border-radius: 20px;
  padding: 15px;
  box-shadow: 0px 0px 10px #888888;
  overflow: scroll;
  height: fit-content;
}

.hint-container {
  @include overlay();

  p {
    font-size: 1.1rem;
    margin: 5px;
  }

  grid-area: 3 / 4 / 4 / 6;

  &__button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
  }
}

.mapoverlay-bar-controls-container {
  grid-area: 8 / 5 / 9 / 6;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 8px 0;
  margin-right: 35px;
}

.mapoverlay-controls__wrapper {
  width: 60px;
  height: 60px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;

  &.active {
    background: #111d3a;

    svg {
      color: #fff;
    }
  }

  svg {
    width: 30px;
    height: 30px;
  }
}

.map-left-menu {
  &__wrapper {
    position: absolute;
    height: 100%;
    width: 30%;
    min-width: 250px;
    max-width: 500px;

    &.closed {
      pointer-events: none;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 5px;
    transition: all 300ms ease-in-out;

    .closed & {
      transform: translateX(-100%);
    }

    > *:last-child {
      margin-top: auto;
    }

    &__disclosures {
      display: flex;
      flex-direction: column;
      gap: 10px;
      overflow-y: scroll;
    }
  }

  &__close {
    opacity: 1;
    height: 50px;
    width: 50px;
    background-color: white;
    position: absolute;
    right: -55px;
    top: 5px;
    transition: all 300ms ease-in-out;
    cursor: pointer;

    svg {
      padding: 10px;
      width: 100%;
      height: 100%;
      color: black;
    }

    .closed & {
      opacity: 0;
      transform: translateX(-500px);
    }
  }

  &__open {
    position: absolute;
    bottom: 20px;
    left: 40px;
    height: 50px;
    width: 50px;
    opacity: 1;
    transition: opacity 300ms ease-in-out;

    .opened & {
      opacity: 0;

      button {
        pointer-events: none;
      }
    }
  }

  &__weather {
    &__wrapper {
      display: flex;
      justify-content: space-evenly;
    }

    &__icon {
      width: 70%;
      height: auto;
    }

    &__entry {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
    }

    &__title {
      color: #0e1d3e;
      font-weight: 700;
      padding-top: 15px;
      margin-bottom: -15px; // needed as the openweather icons have a large padding
    }

    &__content {
      flex-grow: 1;
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      &.temp {
        font-size: 28px;
        font-weight: 500;
      }

      &.wind {
        display: flex;

        &__data {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }

    &__wind-marker {
      width: 30%;
      height: auto;
      margin: 0 15px;
    }
  }

  &__forcesOverview {
    &__table {
      overflow-y: scroll;
      width: 100%;

      > tbody,
      > thead {
        gap: 10px;

        > tr {
          justify-content: space-between;
        }
      }

      > thead {
        font-weight: 700;

        > tr {
          svg {
            height: 24px;
            aspect-ratio: 1/1;
          }
        }
      }

      &__column {
        &__section-name {
          width: 100%;
        }
      }

      &__header {
        &__section-name {
          text-align: start;
        }
      }
    }

    &__footer {
      display: flex;
      justify-content: space-between;
    }
  }
}
